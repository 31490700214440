<template>
    <v-container
            fill-height
            fluid
            grid-list-xl
    >
      <add-company-dialog></add-company-dialog>
        <v-layout>
            <v-snackbar v-model="createdProduct.show" top color="success">
                <v-avatar>
                    <v-img :src="`${imgHost}imgs/products/${createdProduct.product_type.slug}/${createdProduct.img_url}?w=150`"/>
                </v-avatar>
                <p>{{createdProduct.name}} added successfully</p>
            </v-snackbar>

            <v-alert v-if="error.message" class=" white--text" color="error">
                <v-icon left color="white">mdi-alert</v-icon>{{error.message}}
            </v-alert>
            <v-skeleton-loader
                    v-if="isLoading"
                    transition="fade-transition"
                    style="width: 100%"
                    type="table"
            >
            </v-skeleton-loader>
            <v-data-table
                    v-else
                    :headers="headers"
                    :items="products"
                    sort-by="first_name"
                    class="elevation-1 col-12"
                    :search="search_phrase"
            >
                <DataTableTop slot="top" @search="parseSearch">
<!--                    <v-btn @click="onAddCompany" rounded color="white" class=" green&#45;&#45;text"><v-icon left>mdi-tag</v-icon> Company </v-btn>-->
                    <v-btn @click="addItem" rounded color="white" class=" green--text"><v-icon left>mdi-tag</v-icon> Add Product </v-btn>
                </DataTableTop>

                <template v-slot:item.avatar="{ item }">
                    <v-avatar>
                        <v-img :src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}?w=150`"/>
                    </v-avatar>
                </template>
               <template v-slot:item.createdAt="{ item }">
                   {{item.createdAt | moment('Do MMM YYYY')}}
               </template>
                <template v-slot:item.action="{ item }">

                    <v-icon
                            color="success"
                            small
                            class="mr-3"
                            @click="editItem(item)"
                    >
                        edit
                    </v-icon>
                    <v-icon
                            color="error"
                            small
                            @click="setConfirmationDelete(item)"
                    >
                        delete
                    </v-icon>
                </template>

            </v-data-table>
            <v-dialog v-model="pDialog" max-width="520px">
                <v-card>
                    <v-toolbar class="primary" dark>
                        <span class="headline">{{ formTitle }}</span>
                        <v-spacer/>
                        <v-btn icon class="mr-3" @click="onCloseProduct">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        @keyup.enter="save"
                                        filled
                                        maxlength="30"
                                        v-model="editedItem.name" label="Product Name*">
                                            <v-icon slot="prepend">mdi-tag</v-icon>
                                        </v-text-field>

                                    <v-select
                                        v-model="editedItem.product_type" label="Product Type*"
                                        @change="getCategories"
                                        :items="productTypes"
                                        filled
                                        item-value="id"
                                        item-text="name"
                                    >
                                        <v-icon slot="prepend">mdi-tag-heart</v-icon>
                                    </v-select>

                                    <v-select
                                        v-model="editedItem.category" label="Product Categories*"
                                        :items="productCategories"
                                        filled
                                        item-value="id"
                                        item-text="name"
                                    >
                                        <v-icon slot="prepend">mdi-tag-text</v-icon>
                                    </v-select>


                                    <v-text-field
                                        @keyup.enter="save"
                                        filled
                                        type="number"
                                        min="1"
                                        v-model="editedItem.price" label="Price (GHS)*">
                                        <v-icon slot="prepend">mdi-cash</v-icon>
                                    </v-text-field>

                                    <v-row >
                                        <v-col cols="6">
                                            <v-text-field
                                                @keyup.enter="save"
                                                filled
                                                min="6"
                                                type="number"
                                                v-model="editedItem.weight" label="Weight(optional)">
                                                    <v-icon slot="prepend">mdi-weight</v-icon>
                                                </v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select
                                            v-model="editedItem.units" label="Units (optional)"
                                            :items="['kg','g','lb']"
                                            filled
                                            item-value="id"
                                            item-text="name"
                                        >
                                            <v-icon slot="prepend">mdi-playlist-check</v-icon>
                                        </v-select>


                                        </v-col>
                                    </v-row>

                                    <v-text-field
                                            @keyup.enter="save"
                                            filled
                                            v-model="editedItem.usage"
                                            label="Usage (eg. Home use, Restaurants, etc)">
                                            <v-icon slot="prepend">mdi-waves</v-icon>
                                    </v-text-field>
                                    <v-text-field
                                            @keyup.enter="save"
                                            filled
                                            type="number"
                                            v-model="editedItem.minimum"
                                            min="1"
                                            label="Minimum Orders(optional)">
                                            <v-icon slot="prepend">mdi-numeric-9-plus-circle</v-icon>
                                    </v-text-field>

                                    <uploader
                                        :isUpdate="is_update"
                                        :placeholder="editedItem"
                                        icon="mdi-image"
                                        label="Upload image"
                                        name="img_url"
                                        @change="setURL"
                                        />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn rounded color="grey" text @click="close">Cancel</v-btn>
                        <v-btn :disabled="canSave" :loading="is_saving" rounded color="primary" @click="save">
                           {{is_update? 'Update':'Save' }}<v-icon right>mdi-content-save</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
           <v-dialog
                   v-model="deletedItem.show"
                   width="500"
           >
               <v-card style="border-raduis: 32px">
                   <v-card-title
                           class="white&#45;&#45;text red"
                           primary-title
                   >
                       <v-row
                               justify="space-between"
                               align="start"
                       >
                           <p>Delete Product</p>
                           <v-btn icon color="white" @click="deletedItem.show = false">
                               <v-icon >close</v-icon>
                           </v-btn>
                       </v-row>
                   </v-card-title>

                   <v-card-text class=" subtitle-1 pt-5">
                       Deleted Product can not be retrived.
                       Are you sure you want to delete the Product?
                   </v-card-text>

                   <v-divider></v-divider>

                   <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn
                               :loading="deleting"
                               color="red"
                               text
                               @click="deleteItem"
                       >
                           Confirm
                       </v-btn>
                   </v-card-actions>
               </v-card>
           </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import Crud from '../../../gql/PrismaCrud.gql'
    import DataTableTop from "../../../components/datatable/DataTableTop";
    import Uploader from '../../../components/datatable/FirebaseProductTypeImageUploader'
    import AddCompanyDialog from "../../Website/Business/addCompanyDialog";
    // import mixpanel from "mixpanel-browser";
    // mixpanel.init("6a7537bf8311e4fc76404e3362906a58", { debug: true });

    export default {
        name: "BusinessProductsDataTable",
        components: {AddCompanyDialog, DataTableTop, Uploader},
        data(){
            return {
                ProductType: new Crud('productType'),
                Product : new Crud('product'),
                ProductCategory : new Crud('category'),
                createdProduct: {
                    product_type: ''
                },
                img_upload_counter: 0,
                isLoading: false,
                is_saving: false,
                is_update: false,
                deleting: false,
                search_phrase: '',
                defaultItem: {},
                editedItem: {},
                deletedItem: {},
                processedItem: {},
                error: {},
                user: this.$store.getters['auth/getUserData'],
                products:  [],
                productTypes: [],
                productCategories: [],
                productReturnType: `{
                    id
                    name
                    img_url
                    lazy_url
                    quantity
                    status
                    weight
                    units
                    minimum
                    usage
                    price
                    product_type{
                        id
                        name
                        slug
                        categories{
                        name
                        }
                    }
                    category{
                        id
                        name
                        slug
                    }
                    company{
                    id
                    name
                    location
                    }
                    createdAt
                }`,
                headers: [
                    { text: 'Image', value: 'avatar' },
                    { text: 'Product Name *', align: 'left', value: 'name' },
                    { text: 'Product Type', align: 'left', value: 'product_type.name' },
                    { text: 'Category', value: 'category.name', align : 'left' },
                    { text: 'Price (GHc)', value: 'price', align : 'left' },
                    { text: 'Weight', value: 'weight', align : 'left' },
                    { text: 'Date Added', value: 'createdAt', align : 'left' },
                    { text: 'Actions', value: 'action', sortable: false },
                ],
            }
        },
        computed:{
          pDialog(){
            return this.$store.getters.getCreateItemDialog;
          },
            formTitle () {
                return this.is_update === false ? `New Product` : `Edit Product`
            },
            canSave: function () {
                if(this.img_upload_counter)
                return !(
                    this.editedItem.name &&
                    this.editedItem.product_type &&
                    this.editedItem.category &&
                    this.editedItem.price &&
                    this.editedItem.img_url
                )
            }
        },
      mounted () {
        // mixpanel.track("Dashboard Visit", {
        //   dashboard: 'viewed',
        // });
      },
        methods: {
          onAddProduct(){
            this.$store.commit('setCreateProductDialog', true)
          },
          onCloseProduct() {
            this.$store.commit('setCreateProductDialog',false)
          },
          parseSearch(search){
                this.search_phrase = search
            },

            setURL(res){
                this.editedItem.img_url = res.fileName
                this.img_upload_counter++
            },
            getProductTypes(){
                this.ProductType.find(`{id name slug categories{name id}}`)
                    .then(res=>{
                        this.productTypes = res.data
                    }).catch(err=>{
                        console.log(err)
                    })
            },
            getCategories(id){
                let productType = this.productTypes.find(type=>{
                    return type.id === id
                })
                this.productCategories = productType.categories
                setTimeout(()=>{
                    this.$root.$emit('setProductTypeSlug', productType.slug)
                }, 500)

            },
            getProducts(){
                this.isLoading = true
                const company_id = this.user?.company?.id
                this.Product.find( this.productReturnType, {
                    where: {
                        company: {
                            id: company_id,
                        },
                        status: "active"
                    }, orderBy: 'updatedAt_DESC'
                }).then(products=>{
                    this.products = products.data
                }).catch(err=>{
                    this.error = err
                }).finally(_=>{
                    this.isLoading = false
                })
            },

            addItem(){
              const company_id = this.user.company.id
              //If there is no company
              // onAddCompany
              console.log({company: company_id})
              if(!company_id) {
                this.onAddCompany()
              }else{
                this.is_update = false
                this.editedItem = {}
                this.onAddProduct()
              }

            },
          onAddCompany(){
                this.$store.commit('setCompanyDialog', true)
            },

            updateItem(){
                delete this.processedItem.__typename
                let id = this.processedItem.id
                delete this.processedItem.id
                return this.Product.update(this.productReturnType, {
                    where: {
                        id
                    },
                    data: this.processedItem
                }).then(res=>{
                    this.getProducts()
                }).catch(err=>{
                    console.error(err)
                })
            },
            createItem(){
                return this.Product.insert(this.productReturnType, {
                    data: this.processedItem
                }).then(res=>{
                    this.createdProduct = {...res, show: true}
                    this.getProducts()
                })
            },

            setConfirmationDelete (item) {
                this.deletedItem = { show: true, ...item }
            },

            deleteItem(){
                this.deleting = true
                return this.Product.update(`{id}`, {
                    where: {
                        id: this.deletedItem.id
                    },
                    data: {
                        status: 'deleted'
                    }
                }).then(()=>{
                    this.deletedItem.show = false
                    this.deletedItem = {}
                    this.getProducts()
                }).catch(err=>{
                    console.error({err})
                }).finally(_=>{
                    this.deleting = false
                })
            },
            formatSave(){
                this.processedItem = {...this.editedItem}
                this.processedItem.weight = parseInt(this.processedItem.weight)
                this.processedItem.price = parseInt(this.processedItem.price)

                this.processedItem.product_type = { connect: { id : this.processedItem.product_type.id || this.processedItem.product_type} }
                this.processedItem.category = { connect: { id : this.processedItem.category.id || this.processedItem.category } }
                this.processedItem.company = { connect: { id : this.user.company.id } }
                console.log("77777777777777777777777777777"+JSON.stringify(this.processedItem))
                if(this.processedItem.createdAt)
                    delete this.processedItem.createdAt
            },
            save () {
                this.is_saving = true
                this.formatSave()
                if (this.is_update === true) {
                    this.updateItem().then(_=>{
                        this.editedItem = {}
                        this.close()
                    })
                } else {
                    this.createItem().then(_=>{
                        this.editedItem = {}
                        this.close()
                    })
                }
            },

            close () {
                this.img_upload_counter = 0
                this.onCloseProduct()
                this.$root.$emit('reset-img-uploader')
                setTimeout(() => {
                    this.editedItem = this.defaultItem
                    this.is_update = false
                }, 300)
                this.is_saving = false
            },

            editItem(item){
                this.is_update = true
                this.editedItem =  {...item}
                this.onAddProduct()
                this.getCategories(item.product_type.id)
                console.log({item})

            }
        },
        created(){

            this.getProducts()
            this.getProductTypes()

        }
    }
</script>
